import { showFeedback } from '@itau-loans-www/shopping/src/hooks'
import { events, goTo } from 'utils'

import messageDictionary from './messageDictionary'
import redirectTryAgain from './redirectTryAgain'

const helpers = {
  matchWithEndpoint: (url, endpoint) => {
    const urlValues = url.split('/')
    return urlValues.some((item) => item === endpoint)
  }
}

const dispatchErrorEvent = (message, args = {}) => {
  events.alert.show({
    type: 'error',
    icon: true,
    description: message,
    ...args
  })
}

export default (error) => {
  const errorResponse = error?.response
  const errorData = errorResponse?.data
  const errorStatus = errorResponse?.status
  const errorMessage = errorResponse?.data?.message
  const errorEndpoint = errorResponse?.config?.url
  const errorMethod = errorResponse?.config?.method

  if (errorStatus === 503) {
    goTo('error_something_wrong')
    return Promise.reject(error)
  }

  if (errorData?.new_error) {
    if (
      (errorData?.alert_message || errorData?.friendly_message) &&
      errorData?.go_to
    ) {
      dispatchErrorEvent(errorMessage)
      setTimeout(() => {
        goTo(errorData?.go_to)
      }, 3500)

      return Promise.reject(error)
    }

    if (errorData?.alert_message) {
      dispatchErrorEvent(errorMessage)
      return Promise.reject(error)
    }

    if (errorData?.go_to) {
      goTo(errorData?.go_to)
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }

  const shoppingWithEmptyProposal = //TO DO: investigar se ainda faz sentido
    errorStatus === 404 &&
    helpers.matchWithEndpoint(errorEndpoint, 'simulation_summary')

  const fgtsWithEmptyProposal =
    errorStatus === 404 && helpers.matchWithEndpoint(errorEndpoint, 'proposals')

  const fgtsNotAllowed =
    [404, 422].includes(errorStatus) &&
    helpers.matchWithEndpoint(errorEndpoint, 'fgts-allowed') &&
    errorMethod === 'get'

  const prospect =
    helpers.matchWithEndpoint(errorEndpoint, 'prospect') &&
    errorMethod === 'post'

  const exceptions = [
    fgtsNotAllowed,
    fgtsWithEmptyProposal,
    shoppingWithEmptyProposal,
    prospect
  ]

  const hasExceptions = exceptions.some((exception) => exception === true)

  if (!hasExceptions) {
    showFeedback({
      detail: {
        message: messageDictionary[errorMessage] ?? errorMessage,
        show: true,
        type: 'error'
      }
    })
  }

  const proposalAlreadyFinished =
    errorMessage ===
    '422: Não é possível alterar os dados do seu pedido, ele já está concluído.'

  const invalidOrExpiredAuth = errorMessage === 'invalid or expired jwt'

  const hasUndefinedAtEndpoint = /undefined/.test(errorEndpoint)

  if (
    invalidOrExpiredAuth ||
    proposalAlreadyFinished ||
    hasUndefinedAtEndpoint
  ) {
    redirectTryAgain()
  }

  return Promise.reject(error)
}
