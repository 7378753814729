import { storage } from 'utils'

import auth from './auth'

export const defaultLeadsApiCall = async ({
  endpoint,
  method,
  body = {},
  config = {}
}) => {
  const { leadId } = storage.session.getItem('token')

  const response = await auth({}).then((_http) =>
    _http[method](
      `v2/itau-360-shopping-api/leads/${leadId}/${endpoint}`,
      body,
      config
    )
  )

  return response
}
