import throttle from 'throttleit'

export default () => {
  let scrollDown = 0

  let sent = {
    0: false,
    25: false,
    50: false,
    75: false,
    100: false
  }

  const onScroll = () =>
    throttle(() => {
      const onScrollTrack = () => console.log('[ Segment ] - Scroll')

      if (!document.body) return

      const windowHeight = document.body.scrollHeight - window.innerHeight

      if (windowHeight < 0) return

      const dispachTrack = (percentage) => {
        if (!sent[percentage]) {
          window.analytics.track(
            'trackScroll',
            {
              scroll: scrollDown,
              scrollLenght: `${percentage}%`
            },
            onScrollTrack
          )

          sent[percentage] = true
        }
      }

      if (!sent[0]) {
        dispachTrack(0)
      }

      const wasScrolled = (percentage) => {
        const scrolled = {
          100: scrollDown >= windowHeight * 1,
          75: scrollDown >= windowHeight * 0.75,
          50: scrollDown >= windowHeight * 0.5,
          25: scrollDown >= windowHeight * 0.25
        }

        return scrolled[percentage]
      }

      if (wasScrolled(100)) {
        dispachTrack(100)
      } else if (wasScrolled(75)) {
        dispachTrack(75)
      } else if (wasScrolled(50)) {
        dispachTrack(50)
      } else if (wasScrolled(25)) {
        dispachTrack(25)
      }

      scrollDown = window.pageYOffset

      const tracked = Object.values(sent).every((v) => v)

      if (tracked) {
        window.removeEventListener('scroll', onScroll)
      }
    }, 200)

  let scrollReference = null

  scrollDown = 0
  sent = Object.keys(sent).reduce((acc, key) => ({ ...acc, [key]: 0 }), {})

  window.removeEventListener('scroll', scrollReference, false)
  scrollReference = onScroll()
  window.addEventListener('scroll', scrollReference, false)
}
