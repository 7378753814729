import routes from 'Routes'

import getDevice from '../../envs/getDevice'
import { getPageViewData } from './helpers'

export default ({ data = {}, payload = {} }) => {
  const { pageCategory, pageName } = getPageViewData()
  const { location, prevLocation } = data
  const device = getDevice.device()
  const routeAttr = Object.entries(routes).find(([, value]) => {
    return value.path === location?.pathname
  })
  const cachedReferrer = sessionStorage.getItem('lastPath') ?? ''

  console.log('[ Segment ] - PageView', { payload })

  window.analytics.page(
    pageCategory,
    pageName,
    {
      url: location.href,
      path: location.pathname,
      referrer: prevLocation
        ? `${prevLocation?.host}${prevLocation?.pathname}`
        : cachedReferrer,
      title: routeAttr?.[1]?.title,
      ...payload
    },
    { device }
  )
}
