import React, { createContext, useContext, useCallback, useState } from 'react'

import { storage } from 'utils'

const storageName = `${APP_NAME}_${BUILD_ENV}` ?? ''

const ProposalContext = createContext({})

const loadState = (state) => {
  return storage.session.getItem(`${storageName}_${state}`) ?? {}
}

const ProposalProvider = ({ children }) => {
  const [loading, setIsLoading] = useState(false)
  const [product, setProduct] = useState(loadState('product'))

  const changeProduct = useCallback((payload) => {
    const data = {
      ...loadState('product'),
      ...payload
    }

    setProduct(data)
    storage.session.setItem(`${storageName}_product`, JSON.stringify(data))
  }, [])

  const cleanProduct = useCallback(() => {
    setProduct({})
    storage.session.setItem(`${storageName}_product`, JSON.stringify({}))
  }, [])

  return (
    <ProposalContext.Provider
      value={{
        loading,
        product,
        setProduct: changeProduct,
        setIsLoading,
        cleanProduct
      }}
    >
      {children}
    </ProposalContext.Provider>
  )
}

const useProposal = () => {
  const context = useContext(ProposalContext)

  return context
}

export { ProposalProvider, useProposal, ProposalContext }
