import React, { createContext, useContext, useState, useCallback } from 'react'

import { storage } from 'utils'

const storageName = `${APP_NAME}_${BUILD_ENV}` ?? ''

const LeadContext = createContext({})

const loadState = (state) => {
  const loadedState = {
    ...storage.session.getItem(`${storageName}_${state}`)
  }

  return loadedState ?? {}
}

const LeadProvider = ({ children }) => {
  const [offers, setOffers] = useState(loadState('offers'))
  const [offerModalOpen, setOfferModalOpen] = useState(false)
  const [offerModalContent, setOfferModalContent] = useState({})
  const [leadData, setLeadData] = useState({
    vwoVariant: 'A',
    ...loadState('leadData')
  })

  const changeOffers = useCallback((payload) => {
    const data = {
      ...loadState('offers'),
      ...payload
    }
    setOffers(data)
    storage.session.setItem(`${storageName}_offers`, JSON.stringify(data))
  }, [])

  const changeLeadData = useCallback((payload) => {
    const data = {
      ...loadState('leadData'),
      ...payload
    }

    setLeadData(data)
    storage.session.setItem(`${storageName}_leadData`, JSON.stringify(data))
  }, [])

  return (
    <LeadContext.Provider
      value={{
        offers,
        offerModalOpen,
        setOfferModalOpen,
        offerModalContent,
        setOfferModalContent,
        leadData,
        changeOffers,
        changeLeadData
      }}
    >
      {children}
    </LeadContext.Provider>
  )
}

const useLead = () => {
  const context = useContext(LeadContext)

  return context
}

export { LeadProvider, useLead, LeadContext }
