import dispatcher from './dispatcher'
import buttonClick from './events/buttonClick'
import pageView from './events/pageView'
import getPageAlias from './pageAliases'

export default {
  init(locationProps) {
    const pageName = getPageAlias(locationProps)

    dispatcher('pageView', { pageName, data: locationProps })
    dispatcher('scroll', { pageName, data: locationProps })
  },
  buttonClick,
  pageView
}
