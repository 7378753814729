import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const putSimulationSummary = async (requestBody) => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'simulation_summary',
    method: 'put',
    body: requestBody
  })

  return data
}

const finishWebAPPProposal = async (simulationOptionUuid = '') => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'proposals',
    method: 'post',
    body: {
      simulation_option_uuid: simulationOptionUuid
    }
  })

  return data
}

export default { putSimulationSummary, finishWebAPPProposal }
