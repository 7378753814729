const scrollToElement = (selector, options = {}) => {
  const behavior = options.behavior || 'smooth'

  const element = document.querySelector(selector)

  if (!element) return

  element.scrollIntoView({ behavior })
}

export default scrollToElement
