const toNumber = ({
  style = 'decimal',
  value = 0,
  currency = 'BRL',
  decimals = 2,
  currencyDisplay = 'symbol'
}) => {
  return Intl.NumberFormat('pt-BR', {
    style,
    currency,
    currencyDisplay,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value)
}

const toCurrency = (value, decimals = 2) =>
  toNumber({ style: 'currency', decimals, value })

const toFloat = (value) => toNumber({ value })

const currencyToFloat = (value = '') => {
  if (isNaN(value)) {
    const formattedValue = value.replace(/([R$\s]|[.])/g, '').replace(/,/g, '.')
    return Number(parseFloat(formattedValue))
  }
  return Number(value)
  // -> found a better solution here: requested value sometimes returns a stringSimilarity
  // -> maybe the same occurs to replace: https://stackoverflow.com/questions/46757305/why-parsefloat-in-javascript-returns-string-type-for-me
}

const toPercent = (value, digits) =>
  (value / 100).toLocaleString('pt-br', {
    style: 'percent',
    minimumFractionDigits: digits
  })

export default {
  toNumber,
  toCurrency,
  currencyToFloat,
  toPercent,
  toFloat
}
