import Routes from 'Routes'

import getDevice from '../../envs/getDevice'

const devices = {
  mobile: getDevice.isMobile,
  tablet: getDevice.isTablet,
  desktop: getDevice.isDesktop
}

const getPageViewData = () => {
  const { name, category } = Object.entries(Routes)
    .reduce(
      (acc, [name, { path, category }]) => {
        const isRoot = window.location.pathname === '/'
        const index = window.location.pathname.length - 1
        const endsWithSlash = !isRoot && window.location.pathname[index] === '/'

        const pathname = endsWithSlash
          ? window.location.pathname.slice(0, index)
          : window.location.pathname

        if (pathname === path) {
          return [
            ...acc,
            {
              name,
              category
            }
          ]
        }
        return acc
      },
      [{}]
    )
    .pop()

  const device = Object.keys(devices).reduce((acc, device) => {
    const matches = devices[device]

    if (matches()) {
      return device
    }
    return acc
  }, 'mobile')

  return {
    pageName: name?.toUpperCase() ?? '',
    pageCategory: category ?? '',
    device
  }
}

export { getPageViewData }
