import { API_BASE, API_KEY } from 'gatsby-env-variables'

import axios from 'axios'
import { storage } from 'utils'

import { onResponseSuccess, onResponseError } from './interceptors'

const _http = axios.create({
  baseURL: API_BASE
})

_http.interceptors.response.use(onResponseSuccess, onResponseError)

const getAuth = () => {
  if (!Object.keys(storage.session.getItem('token') || {}).length) return {}

  return storage.session.getItem('token')
}

const authorization = async () => {
  const request = _http.get(`v2/authorization/`)

  const { data } = await request

  return data
}

const auth = async ({ auth = true }) => {
  const authData = getAuth()

  if (auth) {
    _http.defaults.headers.common['app'] = API_KEY

    if (authData?.accessToken) {
      _http.defaults.headers.common['token'] = `Bearer ${authData.accessToken}`
    } else {
      const authRequest = await authorization()

      const token = { ...authData, accessToken: authRequest?.token }

      storage.session.setItem('token', JSON.stringify(token))

      _http.defaults.headers.common['token'] = `Bearer ${authRequest?.token}`
    }
  }

  return Promise.resolve(_http)
}

export default auth
