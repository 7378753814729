import React, { createContext, useContext, useState } from 'react'

const LpControlContext = createContext({})

const LpControlProvider = ({ children }) => {
  const [hideSimulateButton, setHideSimulateButton] = useState(true)
  const [openWppModal, setOpenWppModal] = useState(false)

  return (
    <LpControlContext.Provider
      value={{
        hideSimulateButton,
        setHideSimulateButton,
        openWppModal,
        setOpenWppModal
      }}
    >
      {children}
    </LpControlContext.Provider>
  )
}

const useLpControl = () => {
  const context = useContext(LpControlContext)

  return context
}

export { LpControlProvider, useLpControl, LpControlContext }
