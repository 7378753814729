const trackClick = ({
  currentPage,
  detail,
  customLayer = {},
  eventName = 'click'
}) => {
  const data = {
    analytics: {
      event_name: eventName,
      parameters: {
        custom_path: `/emprestimo/shopping/credito/${currentPage}`,
        implementation_team: 'visao-cliente:conquista-digital-de-clientes',
        detail: detail,
        ...customLayer
      }
    }
  }

  console.log(`[ GA ] - Click ${currentPage} ${detail}`)

  return data
}

export default trackClick
