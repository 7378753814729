import { BUILD_ENV } from 'gatsby-env-variables'

import storage from '../storage'
import isClient from './isClient'

export const getSourceUrl = () => {
  if (isClient()) {
    if (BUILD_ENV === 'development') {
      return 'https://www.shopping-360.dev.aquisicaodigitalitau.com.br'
    }
    const sourceUrl = storage.session.getItem('source_url')
    if (sourceUrl) {
      return sourceUrl
    }
    return window.location.href
  }
}

export const getSourceUrlWithQuerys = (variant, testName) => {
  if (isClient()) {
    let sourceUrlWithQuerys = getSourceUrl()

    if (variant && testName) {
      sourceUrlWithQuerys = `${sourceUrlWithQuerys}${
        !sourceUrlWithQuerys.includes('?') ? '?' : '&'
      }vwo_id=${testName}&vwo_variant=${variant}`
    }

    return sourceUrlWithQuerys
  }
}

export const setSourceUrl = () => {
  const sourceUrl = storage.session.getItem('source_url')

  // A3S Error in source_url type when is localhost. Setting manually when local and development env.
  if (isClient() && BUILD_ENV === 'development') {
    return storage.session.setItem(
      'source_url',
      'https://www.shopping-360.dev.aquisicaodigitalitau.com.br'
    )
  }

  if (isClient() && !sourceUrl) {
    storage.session.setItem('source_url', window.location.href)
  }
}
