import isClient from './isClient'

export const mobileDevicesRegex =
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i

const isDesktop = () => {
  if (!isClient()) return

  return !!window.matchMedia('screen and (min-width:1025px)').matches
}

const isTablet = () => {
  if (!isClient()) return

  return !!window.matchMedia(
    'screen and (min-width:640px) and (max-width: 1024px)'
  ).matches
}

const isMobile = () => {
  if (!isClient()) return true

  return mobileDevicesRegex.test(window.navigator.userAgent)
}

const device = () => {
  const devices = {
    mobile: isMobile,
    tablet: isTablet,
    desktop: isDesktop
  }

  return Object.keys(devices).reduce((acc, device) => {
    const matches = devices[device]

    if (matches()) {
      return device
    }
    return acc
  }, 'mobile')
}

export default {
  isDesktop,
  isTablet,
  isMobile,
  device
}
