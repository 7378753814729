let SCRIPTS_LOADING = {}
export const addScript = (url) => {
  const createScript = (resolve) => {
    SCRIPTS_LOADING[url] = true
    const script = document.createElement('script')
    script.src = url
    script.async = true
    script.onload = () => {
      SCRIPTS_LOADING[url] = false
      resolve()
    }

    return script
  }

  return new Promise((resolve) => {
    const query = document?.querySelector(`script[src="${url}"`)

    if (query) {
      const prevCallback = query.onload
      if (SCRIPTS_LOADING[url] && prevCallback) {
        query.onload = () => {
          prevCallback()
          resolve()
        }
      } else {
        resolve()
      }
    } else {
      SCRIPTS_LOADING[url] = true
      const script = createScript(resolve)
      document?.body?.appendChild(script)
    }
  })
}
