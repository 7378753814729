import isClient from '../envs/isClient'
import storage from '../storage'

const getOnUrl = (regex) => {
  let queries = {}

  if (isClient()) {
    const arrRegex = Array.isArray(regex) ? regex : [regex]

    const params = Array.from(
      new URLSearchParams(window.location.search).entries()
    )

    queries = arrRegex
      .map((r) => {
        return params
          .filter((param) => new RegExp(r).test(param[0]))
          .reduce((acc, [key, value]) => {
            return {
              ...acc,
              [key]: value
            }
          }, {})
      })
      .reduce((acc, value) => {
        return {
          ...acc,
          ...value
        }
      }, queries)
  }

  return queries
}

const get = (regex, itemStorage) => {
  const utmQueries = getOnUrl(regex)
  const isEmpty = Object.keys(utmQueries).length === 0

  if (isEmpty) {
    return storage.session.getItem(itemStorage) ?? {}
  } else {
    return utmQueries
  }
}

const set = (regex, itemStorage) => {
  const utmQueries = getOnUrl(regex)

  sessionStorage.setItem(itemStorage, JSON.stringify(utmQueries))
}

export default {
  get,
  set
}
