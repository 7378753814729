import isClient from '../envs/isClient'

export default (selector) => {
  if (isClient()) {
    const focus = document.querySelector(selector)
    if (focus) {
      focus.tabIndex = '32767'
      focus.focus()
    }
  }
}
