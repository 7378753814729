import buttonClick from './buttonClick'
import generic from './generic'
import pageView from './pageView'
import scroll from './scroll'

export default {
  pageView,
  buttonClick,
  scroll,
  generic
}
