import getQueriesOnUrl from './getQueriesOnUrl'

const regex = [/mp_uuid/, /m_id/]
const itemStorage = 'mens_queries'

export default {
  get: () => {
    const { mp_uuid } = getQueriesOnUrl.get(regex, itemStorage)
    const mappedValues = { person_id: mp_uuid ?? '' }
    return mappedValues
  },
  set: () => getQueriesOnUrl.set(regex, itemStorage)
}
