import auth from './auth'

const getStates = async () => {
  const { data } = await auth({}).then((_http) =>
    _http.get(`v2/itau-360-shopping-api/states/`)
  )

  return data
}

export default {
  getStates
}
