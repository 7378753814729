import { SEGMENT as id } from 'gatsby-env-variables'

const startSegment = () => {
  const analytics = (window.analytics = window.analytics || [])
  if (!analytics.initialize) {
    if (analytics.invoked) {
      console.log('[ Segment ] - Analytics was started!')
    } else {
      analytics.invoked = !0
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware'
      ]
      analytics.factory = function (e) {
        return function () {
          var t = Array.prototype.slice.call(arguments)
          t.unshift(e)
          analytics.push(t)
          return analytics
        }
      }
      for (var e = 0; e < analytics.methods.length; e++) {
        var key = analytics.methods[e]
        analytics[key] = analytics.factory(key)
      }
      analytics.load = function (key, e) {
        var t = document.createElement('script')
        t.type = 'text/javascript'
        t.defer = 1
        t.rel = 'preload'
        t.src =
          'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'
        var n = document.getElementsByTagName('script')[0]
        n.parentNode.insertBefore(t, n)
        analytics._loadOptions = e
      }
      analytics._writeKey = id
      analytics.SNIPPET_VERSION = '4.13.2'
      analytics.load(id)
    }
  }
}

export default startSegment()
