module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/home/runner/work/itau-jn6-app-360-shopping-www/itau-jn6-app-360-shopping-www/apps/shopping/src/assets/images/logo.png","icons":[{"src":"/home/runner/work/itau-jn6-app-360-shopping-www/itau-jn6-app-360-shopping-www/apps/shopping/src/assets/images/logo-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/home/runner/work/itau-jn6-app-360-shopping-www/itau-jn6-app-360-shopping-www/apps/shopping/src/assets/images/logo-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/home/runner/work/itau-jn6-app-360-shopping-www/itau-jn6-app-360-shopping-www/apps/shopping/src/assets/images/logo-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/home/runner/work/itau-jn6-app-360-shopping-www/itau-jn6-app-360-shopping-www/apps/shopping/src/assets/images/logo-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/home/runner/work/itau-jn6-app-360-shopping-www/itau-jn6-app-360-shopping-www/apps/shopping/src/assets/images/logo-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/home/runner/work/itau-jn6-app-360-shopping-www/itau-jn6-app-360-shopping-www/apps/shopping/src/assets/images/logo-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/home/runner/work/itau-jn6-app-360-shopping-www/itau-jn6-app-360-shopping-www/apps/shopping/src/assets/images/logo-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/home/runner/work/itau-jn6-app-360-shopping-www/itau-jn6-app-360-shopping-www/apps/shopping/src/assets/images/logo-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ffa241d058633af0011bc0484fd482bd"},
    },{
      plugin: require('../../../node_modules/itau-jn6-dep-www/setup/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"pathStaticFolder":"/apps/shopping/static"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
