import AzulInfiniteHeroCardImage from '../assets/images/cards/azul-infinite-hero-card.png'
import AzulInfiniteSecondCardImage from '../assets/images/cards/azul-infinite-second-card.png'
import AzulPlatinumHeroCardImage from '../assets/images/cards/azul-platinum-hero-card.png'
import AzulPlatinumSecondCardImage from '../assets/images/cards/azul-platinum-second-card.png'
import LatamPassBlackHeroCardImage from '../assets/images/cards/latam-pass-black-hero-card.png'
import LatamPassBlackSecondCardImage from '../assets/images/cards/latam-pass-black-second-card.png'
import LatamPassPlatinumHeroCardImage from '../assets/images/cards/latam-pass-platinum-hero-card.png'
import LatamPassPlatinumSecondCardImage from '../assets/images/cards/latam-pass-platinum-second-card.png'
import PassaiGoldHeroCardImage from '../assets/images/cards/passai-gold-hero-card.png'
import PassaiGoldSecondCardImage from '../assets/images/cards/passai-gold-second-card.png'
import PDABlackHeroCardImage from '../assets/images/cards/pda-black-hero-card.png'
import PDABlackSecondCardImage from '../assets/images/cards/pda-black-second-card.png'
import PDAInternationalHeroCardImage from '../assets/images/cards/pda-internacional-hero-card.png'
import PDAInternationalSecondCardImage from '../assets/images/cards/pda-internacional-second-card.png'
import PersonnaliteBlackHeroCardImage from '../assets/images/cards/personnalite-black-pontos-hero-card.png'
import PersonnaliteBlackSecondCardImage from '../assets/images/cards/personnalite-black-pontos-second-card.png'
import UniclassHeroCardImage from '../assets/images/cards/uniclass-hero-card.png'
import UniclassSecondCardImage from '../assets/images/cards/uniclass-second-card.png'

const cards = [
  {
    title: 'Renegocie suas dívidas',
    subtitle: 'Descontos de até 99%',
    icon: 'gestao_de_crises_outline',
    description: 'Condições especiais para que você recupere seu crédito',
    productCode: 'recovery_debts',
    buttonText: 'Conhecer',
    buttonAriaLabel: 'renegocie agora',
    buttonTrackingName: 'Renegocie',
    heroOfferHighlights: [
      {
        text: 'Super descontos'
      },
      {
        text: 'Pague com Pix'
      },
      {
        text: 'Renegocie online'
      }
    ],
    secondOfferHighlights: [
      {
        text: 'Super descontos'
      },
      {
        text: 'Renegocie online'
      }
    ]
  },
  {
    title: 'Crédito com garantia de imóvel',
    subtitle: 'Até 60% do valor do imóvel',
    icon: 'credito_imobiliario_outline',
    description:
      'Utilize seu imóvel como garantia e conquiste menores taxas de juros',
    buttonText: 'Desbloquear',
    productCode: 'cgi',
    buttonAriaLabel: 'desbloquear',
    buttonTrackingName: 'EmprestimoCGI',
    heroOfferHighlights: [
      {
        infosTitle: 'Pagamento',
        infosDescription: 'Em até 15 anos'
      },
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: 'Imediato'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Pagamento',
        infosDescription: 'Em até 15 anos'
      },
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: 'Imediato'
      }
    ]
  },
  {
    titleArray: ['Antecipação', 'Saque-Aniversário FGTS'],
    icon: 'antecipacao_de_recebimento_outline',
    description:
      'Parte do valor do FGTS direto na sua conta Itaú em até 7 parcelas',
    buttonText: 'Simular',
    productCode: 'FGTS',
    buttonAriaLabel: 'simular',
    buttonTrackingName: 'FGTS',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: 'Em 1 dia útil'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Mês do aniversário'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: 'Em 1 dia útil'
      }
    ]
  },
  {
    titleArray: ['Antecipação', 'Saque-Aniversário FGTS'],
    icon: 'antecipacao_de_recebimento_outline',
    description:
      'Parte do valor do FGTS direto na sua conta Itaú em até 7 parcelas',
    buttonText: 'Simular',
    productCode: 'fgts_app',
    buttonAriaLabel: 'simular',
    buttonTrackingName: 'FGTS APP',
    showInterestRate: true,
    showPreApprovedValue: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: 'Em 1 dia útil'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Mês do aniversário'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: 'Em 1 dia útil'
      }
    ]
  },
  {
    titleArray: ['Antecipação', 'Saque-Aniversário FGTS'],
    subtitle: 'Até 97% do seu saldo FGTS',
    icon: 'antecipacao_de_recebimento_outline',
    description:
      'Parte do valor do FGTS direto na sua conta Itaú em até 7 parcelas',
    buttonText: 'Simular',
    productCode: 'FGTS',
    buttonAriaLabel: 'simular',
    buttonTrackingName: 'FGTS',
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: 'Em 1 dia útil'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Mês do aniversário'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: 'Em 1 dia útil'
      }
    ]
  },
  {
    title: 'Crédito consignado',
    icon: 'credito_consignado_outline',
    description:
      'Taxas atrativas e parcelas descontadas na folha de pagamento ou benefício',
    buttonText: 'Simular',
    productCode: 'consig_ncor',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'ConsigNcor',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Renegocie suas dívidas',
    subtitle: 'Descontos de até 99%',
    icon: 'gestao_de_crises_outline',
    description: 'Condições especiais para que você recupere seu crédito',
    productCode: 'reneg_debts',
    buttonText: 'Conhecer',
    buttonAriaLabel: 'renegociar dívidas',
    buttonTrackingName: 'Renegocie',
    heroOfferHighlights: [
      {
        text: 'Nome limpo em até 5 dias úteis'
      },
      {
        text: 'Renegocie online'
      },
      {
        text: 'Parcele em 72x'
      }
    ],
    secondOfferHighlights: [
      {
        text: 'Nome limpo em até 5 dias úteis'
      },
      {
        text: 'Parcela em 72x'
      }
    ]
  },
  {
    title: 'Crédito consignado',
    icon: 'credito_consignado_outline',
    description:
      'Taxas atrativas e parcelas descontadas na folha de pagamento ou benefício',
    buttonText: 'Simular',
    productCode: 'CONSIG CORR OP',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'ConsignadoCORROP',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Crédito consignado',
    icon: 'credito_consignado_outline',
    description:
      'Taxas atrativas e parcelas descontadas na folha de pagamento ou benefício',
    buttonText: 'Simular',
    productCode: 'CONSIG CORR EP',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'ConsignadoCORREP',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Crédito consignado',
    icon: 'credito_consignado_outline',
    description:
      'Taxas atrativas e parcelas descontadas na folha de pagamento ou benefício',
    buttonText: 'Simular',
    productCode: 'CONSIG CORR OP / Person',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'ConsignadoCORROPPerson',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Crédito consignado',
    icon: 'credito_consignado_outline',
    description:
      'Taxas atrativas e parcelas descontadas na folha de pagamento ou benefício',
    buttonText: 'Simular',
    productCode: 'CONSIG CORR EP / Person',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'ConsignadoCORREPPerson',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Crédito consignado',
    icon: 'credito_consignado_outline',
    description:
      'Taxas atrativas e parcelas descontadas na folha de pagamento ou benefício',
    buttonText: 'Simular',
    productCode: 'consig_pa_ep_cold',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'consig_pa_ep_cold',
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ]
  },
  {
    title: 'Refinanciamento Crédito Consignado',
    icon: 'credito_consignado_outline',
    description:
      'Opção para aliviar as contas, ter mais tempo para pagar e liberar mais crédito',
    buttonText: 'Simular',
    productCode: 'CONSIG CORR REFIN EP',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'ConsignadoCORRRefinEP',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Refinanciamento Crédito Consignado',
    icon: 'credito_consignado_outline',
    description:
      'Opção para aliviar as contas, ter mais tempo para pagar e liberar mais crédito',
    buttonText: 'Simular',
    productCode: 'CONSIG CORR REFIN OP',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'ConsignadoCORRRefinOP',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Refinanciamento Crédito Consignado',
    icon: 'credito_consignado_outline',
    description:
      'Opção para aliviar as contas, ter mais tempo para pagar e liberar mais crédito',
    buttonText: 'Simular',
    productCode: 'CONSIG CORR REFIN EP / Person',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'ConsignadoCORRRefinEPPerson',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Refinanciamento Crédito Consignado',
    icon: 'credito_consignado_outline',
    description:
      'Opção para aliviar as contas, ter mais tempo para pagar e liberar mais crédito',
    buttonText: 'Simular',
    productCode: 'CONSIG CORR REFIN OP / Person',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'ConsignadoCORRRefinOPPerson',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Refinanciamento Crédito Consignado',
    icon: 'credito_consignado_outline',
    description:
      'Opção para aliviar as contas, ter mais tempo para pagar e liberar mais crédito',
    buttonText: 'Simular',
    productCode: 'CONSIG CORR REFIN INSS',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'ConsignadoCORRRefinINSS',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Refinanciamento Crédito Consignado',
    icon: 'credito_consignado_outline',
    description:
      'Opção para aliviar as contas, ter mais tempo para pagar e liberar mais crédito',
    buttonText: 'Simular',
    productCode: 'CONSIG CORR REFIN INSS / Person',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'ConsignadoCORRRefinINSSPerson',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Refinanciamento Crédito Consignado',
    icon: 'credito_consignado_outline',
    description:
      'Opção para aliviar as contas, ter mais tempo para pagar e liberar mais crédito',
    buttonText: 'Simular',
    productCode: 'consig_refin_inss_ncor',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'RenovacaoConsignado',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Refinanciamento Crédito Consignado',
    icon: 'credito_consignado_outline',
    description:
      'Opção para aliviar as contas, ter mais tempo para pagar e liberar mais crédito',
    buttonText: 'Simular',
    productCode: 'CONSIG REFIN INSS',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'RefinINSS',
    showPreApprovedValue: true,
    showInterestRate: true,
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      }
    ]
  },
  {
    title: 'Crédito consignado',
    icon: 'credito_consignado_outline',
    description:
      'Taxas atrativas e parcelas descontadas na folha de pagamento ou benefício',
    buttonText: 'Simular',
    productCode: 'consig_pcon_inss_cold',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'consig_pcon_inss_cold',
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ]
  },
  {
    title: 'Crédito consignado',
    icon: 'credito_consignado_outline',
    description:
      'Taxas atrativas e parcelas descontadas na folha de pagamento ou benefício',
    buttonText: 'Simular',
    productCode: 'consig_pcon_op_cold',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'consig_pcon_op_cold',
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ]
  },
  {
    title: 'Crédito consignado',
    icon: 'credito_consignado_outline',
    description:
      'Taxas atrativas e parcelas descontadas na folha de pagamento ou benefício',
    buttonText: 'Simular',
    productCode: 'consig_pcon_cold',
    buttonAriaLabel: 'Simular',
    buttonTrackingName: 'consig_pcon_op_cold',
    heroOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ],
    secondOfferHighlights: [
      {
        infosTitle: 'Quando cai na conta',
        infosDescription: '2 a 5 dias úteis'
      },
      {
        infosTitle: 'Quando começa a pagar',
        infosDescription: 'Próximo mês'
      }
    ]
  },
  {
    title: 'Conta Itaú',
    subtitle: 'Livre de mensalidade',
    icon: 'piscada_outline',
    description:
      'Movimentar a conta pode aumentar suas chances de conseguir um empréstimo',
    buttonText: 'Continuar',
    productCode: 'itau_account',
    buttonAriaLabel: 'Continuar',
    buttonTrackingName: 'itau_account',
    heroOfferHighlights: [
      {
        text: 'Limite da conta para usar em caso de imprevistos'
      },
      {
        text: 'Investimentos a partir de R$ 1,00'
      }
    ],
    secondOfferHighlights: [
      {
        text: 'Limite da conta para usar em caso de imprevistos'
      },
      {
        text: 'Investimentos a partir de R$ 1,00'
      }
    ]
  },
  ///////// CARTAO /////////
  {
    title: 'Azul Infinite',
    buttonText: 'Pedir cartão',
    highlights: [
      {
        icon: 'presente_outline',
        text: 'Ganhe até 60 mil pontos bônus'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de U$$ 1 = 3,0 pontos'
      },
      {
        icon: 'viagem_outliner',
        text: 'Acompanhante grátis em 2 viagens e 3 bagagens grátis em voos nacionais e internacionais'
      }
    ],
    highlightsSecondary: [
      {
        icon: 'presente_outline',
        text: 'Ganhe até 60 mil pontos bônus'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de U$$ 1 = 3,0 pontos'
      }
    ],
    productCode: 'azul_infinite_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'Pedir cartão',
    buttonTrackingName: 'azulInfiniteCard',
    image: AzulInfiniteHeroCardImage,
    imageSecondary: AzulInfiniteSecondCardImage
  },
  {
    title: 'Azul Platinum',
    buttonText: 'Pedir cartão',
    highlights: [
      {
        icon: 'limite_outline',
        text: 'Limite máximo de R$ 35.000,00'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de U$$ 1 = 2,2 pontos'
      },
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis gastando R$ 4.000,00 por mês'
      }
    ],
    highlightsSecondary: [
      {
        icon: 'limite_outline',
        text: 'Limite máximo de R$ 35.000,00'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de U$$ 1 = 2,2 pontos'
      }
    ],
    productCode: 'azul_platinum_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'Pedir cartão',
    buttonTrackingName: 'azulPlatinumCard',
    image: AzulPlatinumHeroCardImage,
    imageSecondary: AzulPlatinumSecondCardImage
  },
  {
    title: 'Pão de Açúcar Internacional',
    buttonText: 'Pedir cartão',
    highlights: [
      {
        icon: 'limite_outline',
        text: 'Limite de até R$ 10.000,00'
      },
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis sem gasto mínimo'
      },
      {
        icon: 'presente_outline',
        text: '20% OFF: Vinhos, Queijos, Cervejas Especiais'
      }
    ],
    highlightsSecondary: [
      {
        icon: 'limite_outline',
        text: 'Limite de até R$ 10.000,00'
      },
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis sem gasto mínimo'
      }
    ],
    productCode: 'pda_inter_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'PaoDeAcucarCard',
    image: PDAInternationalHeroCardImage,
    imageSecondary: PDAInternationalSecondCardImage
  },
  {
    title: 'Uniclass Signature',
    buttonText: 'Pedir cartão',
    highlights: [
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis'
      },
      {
        icon: 'limite_outline',
        text: 'Limite de até R$ 50 mil no cartão'
      }
    ],
    highlightsSecondary: [
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis'
      },
      {
        icon: 'limite_outline',
        text: 'Limite de até R$ 50 mil no cartão'
      }
    ],
    productCode: 'uniclass_signature_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'UniclassSignature',
    image: UniclassHeroCardImage,
    imageSecondary: UniclassSecondCardImage
  },
  {
    title: 'Personnalité Black Cashback',
    buttonText: 'Pedir cartão',
    highlights: [
      {
        icon: 'cartao_outline',
        text: 'Cartão de crédito com pontos que não expiram'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de até US$ 1 = 3 pontos'
      },
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis gastando R$ 10 mil por mês'
      }
    ],
    highlightsSecondary: [
      {
        icon: 'cartao_outline',
        text: 'Cartão de crédito com pontos que não expiram'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de até US$ 1 = 3 pontos'
      }
    ],
    productCode: 'personnalite_black_pontos_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'PersonnaliteBlack',
    image: PersonnaliteBlackHeroCardImage,
    imageSecondary: PersonnaliteBlackSecondCardImage
  },
  {
    title: 'LATAM Pass Platinum',
    buttonText: 'Pedir cartão',
    highlights: [
      {
        icon: 'limite_outline',
        text: 'Limite a partir de R$ 5.500,00'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de US$ 1 = 2 pontos'
      },
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis gastando R$ 4.000,00 por mês'
      }
    ],
    highlightsSecondary: [
      {
        icon: 'limite_outline',
        text: 'Limite a partir de R$ 5.500,00'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de US$ 1 = 2 pontos'
      }
    ],
    productCode: 'latam_pass_platinum',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'LatamPassPlatinum',
    image: LatamPassPlatinumHeroCardImage,
    imageSecondary: LatamPassPlatinumSecondCardImage
  },
  {
    title: 'LATAM Pass Black',
    buttonText: 'Pedir cartão',
    highlights: [
      {
        icon: 'limite_outline',
        text: 'Limite flexível'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de até US$ 1 = 3,5 pontos'
      },
      {
        icon: 'calendario_outline',
        text: 'Anuidade grátis gastando R$ 20.000,00 por mês'
      }
    ],
    highlightsSecondary: [
      {
        icon: 'limite_outline',
        text: 'Limite flexível'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de até US$ 1 = 3,5 pontos'
      }
    ],
    productCode: 'latam_pass_black',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'LatamPassBlack',
    image: LatamPassBlackHeroCardImage,
    imageSecondary: LatamPassBlackSecondCardImage
  },
  {
    title: 'Pão de Açúcar Black',
    buttonText: 'Pedir cartão',
    highlights: [
      {
        icon: 'presente_outline',
        text: '20% OFF: Vinhos, Espumantes e em toda linha Qualitá'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de até US$ 1 = 5 pontos'
      }
    ],
    highlightsSecondary: [
      {
        icon: 'presente_outline',
        text: '20% OFF: Vinhos, Espumantes e em toda linha Qualitá'
      },
      {
        icon: 'programa_de_pontos_outline',
        text: 'Pontuação de até US$ 1 = 5 pontos'
      }
    ],
    productCode: 'pda_black_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'PdaBlack',
    image: PDABlackHeroCardImage,
    imageSecondary: PDABlackSecondCardImage
  },
  {
    title: 'Passaí Gold',
    buttonText: 'Pedir cartão',
    highlights: [
      {
        icon: 'cobranca',
        text: 'Preço de atacado a partir de 1 unidade com o cartão'
      },
      {
        icon: 'cartao_outline',
        text: 'Condições exclusivas de parcelamento no cartão de crédito'
      },
      {
        icon: 'calendario_outline',
        text: 'Renda mínima R$ 800,00'
      }
    ],
    highlightsSecondary: [
      {
        icon: 'cobranca',
        text: 'Preço de atacado a partir de 1 unidade com o cartão'
      },
      {
        icon: 'cartao_outline',
        text: 'Condições exclusivas de parcelamento no cartão de crédito'
      }
    ],
    hasLongSecondaryHighlights: true,
    productCode: 'passai_gold_card',
    subtitleBig: '',
    subtitleSmall: '',
    buttonAriaLabel: 'pedir cartão',
    buttonTrackingName: 'PassaiGold',
    image: PassaiGoldHeroCardImage,
    imageSecondary: PassaiGoldSecondCardImage
  },
  ///////// CARTAO /////////
  {
    title: 'Consórcio Itaú',
    subtitle: 'Você escolhe o valor que quer pagar',
    icon: 'credito_imobiliario_outline',
    description:
      'Você pode comprar uma moto, uma casa, fazer uma reforma e por aí vai',
    buttonText: 'Conhecer',
    productCode: 'consortium',
    buttonAriaLabel: 'Conhecer',
    buttonTrackingName: 'consorcio',
    heroOfferHighlights: [
      {
        text: 'Sem entrada e sem juros'
      },
      {
        text: 'Todo mês alguém é contemplado'
      },
      {
        text: 'Planejamento e segurança em um só lugar'
      }
    ],
    secondOfferHighlights: [
      {
        text: 'Sem entrada e sem juros'
      },
      {
        text: 'Todo mês alguém é contemplado'
      }
    ]
  }
]

export default cards
