const event = (arg) => {
  const event = new CustomEvent('alert', arg)
  return window.dispatchEvent(event)
}

const show = ({ type, icon, title, description }) => {
  event({ detail: { type, icon, description, title, active: true } })
}

const hide = ({ type, icon, title, description }) => {
  event({ detail: { type, icon, description, title, active: false } })
}

export default {
  show,
  hide
}
