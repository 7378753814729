export const windowTrackClick = (callback) => {
  let clickReference = null

  const onWindowClick = (event) => {
    event.stopImmediatePropagation()

    if (event?.composedPath) {
      const clickedElements = event.composedPath()

      const currentButton = Array.from(clickedElements).find((element) => {
        const tagName = element.nodeName && element.nodeName.toLowerCase()
        const isAButtonOrALink = tagName === 'button' || tagName === 'a'

        return isAButtonOrALink
      })

      if (currentButton) {
        callback(currentButton)
      }
    }
  }

  window.removeEventListener('click', clickReference, false)
  clickReference = onWindowClick
  window.addEventListener('click', clickReference, false)
}
