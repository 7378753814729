import routes from 'Routes'

export default ({ currentButton, locationProps }, payload = {}) => {
  const routeAttr =
    Object.entries(routes).find(([, value]) => {
      return value.path === locationProps?.location?.pathname
    }) ?? []
  const cachedReferrer = sessionStorage.getItem('lastPath') ?? ''

  window.analytics.track(
    'Button Clicked',
    {
      button_text: currentButton?.innerText ?? currentButton?.ariaLabel ?? '',
      destination_url: currentButton?.href ?? '',
      button_id: currentButton?.id ?? '',
      path: location?.pathname ?? '',
      referrer: locationProps?.prevLocation
        ? `${locationProps?.prevLocation?.host}${locationProps?.prevLocation?.pathname}`
        : cachedReferrer,
      url: location?.href ?? '',
      title: routeAttr?.length > 0 ? routeAttr[1]?.title : '',
      ...payload
    },
    () => {
      console.log('[ Segment ] - Button click', { payload })
    }
  )
}
