const toISO = (date) => {
  if (date) {
    const formattedDate = date.split('/').reverse().join('-')

    return new Date(formattedDate).toISOString()
  }
}

const toShortISO = (date) => {
  if (date) {
    return new Date(date).toISOString().slice(0, 10)
  }
}

const toLocale = (date, options = {}) => {
  if (date) {
    const { timeZone = 'utc' } = options
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone,
      ...options
    })
  }
}

const toLong = (date) => {
  return toLocale(date, { year: 'numeric', month: 'long', day: 'numeric' })
}

const toWords = ({ date, isShortMonth }) => {
  if (date) {
    const dateFormat = new Date(date)
    const month = dateFormat.toLocaleDateString('pt-br', { month: 'long' })
    const year = dateFormat.getUTCFullYear()
    const day = dateFormat.getDate()

    if (isShortMonth) {
      return `${day} de ${month.substring(0, 3)} de ${year}`
    }

    return `${day} de ${month} de ${year}`
  }
}

export default {
  toISO,
  toShortISO,
  toLong,
  toLocale,
  toWords
}
