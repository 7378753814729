import platform from 'platform'

import getDevice from '../envs/getDevice'
import { getSourceUrl } from '../envs/handleSourceUrl'
import isClient from '../envs/isClient'
import { getCookie, getGoogleClientId } from './getCookie'
import { getExactQueryString } from './getExactQueryString'
import mensQueries from './mensQueries'
import utmQueries from './utmQueries'

const getAnonymousId = () => {
  return isClient() ? window?.analytics?._user?.anonymousId() : ''
}

const getReferrerUrl = () => {
  return document.referrer
}

const getDeviceInfo = () => {
  const {
    name: browser,
    version: browser_version,
    product: device,
    ua: user_agent,
    os
  } = platform

  const { family: operational_system, version: operational_system_version } = os

  const user_agent_splitted = user_agent.split(/\(([^)]+)\)/)[1]

  const device_type = getDevice.isMobile() ? 'Mobile' : 'Desktop'

  const width = window.screen.width
  const height = window.screen.height

  const screen_size = `${height}x${width}`

  return {
    browser,
    browser_version,
    device,
    user_agent,
    user_agent_splitted,
    operational_system,
    operational_system_version,
    device_type,
    screen_size
  }
}

const get = () => {
  return new Promise((resolve) => {
    if (isClient()) {
      const anonymous_id = getAnonymousId()
      const google_client_id = getGoogleClientId(getCookie('_ga'))
      const facebook_click = getCookie('_fbc')
      const facebook_browser_pixel = getCookie('_fbp')
      const source_url = getSourceUrl()
      const deviceInfo = getDeviceInfo()
      const utm_queries = utmQueries.get()
      const mens_queries = mensQueries.get()
      const google_click_id = getExactQueryString('gclid')
      const facebook_click_id = getExactQueryString('fbclid')
      const referrer_url = getReferrerUrl()

      resolve({
        ...mens_queries,
        ...utm_queries,
        ...deviceInfo,
        anonymous_id,
        google_client_id,
        source_url,
        facebook_click,
        facebook_browser_pixel,
        google_click_id,
        facebook_click_id,
        referrer_url
      })
    }

    resolve({})
  })
}

export default {
  get,
  getSourceUrl
}
