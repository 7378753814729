import { cards } from '@itau-loans-www/shopping/src/constants'
import { formatters } from 'utils'

import { DDPageError } from 'utils/datadog/events'

const useMapper = () => {
  const dictionary = cards?.reduce(
    (acc, item) => ({ ...acc, [item.productCode]: item }),
    {}
  )

  const checkUnexpectedData = (data = [], errorMessage) => {
    const handleChecking = (acc = [], product) => {
      const productCode = product?.product_code
      const simulationOptionUuid = String(product?.simulation_option_uuid)

      const expectedValues = {
        default: {
          offer_uuid: String(product?.offer_uuid) !== '',
          simulation_uuid: String(product?.simulation_uuid) !== '',
          simulation_option_uuid: String(product?.simulation_option_uuid) !== ''
        },
        cgi: {
          offer_uuid: String(product?.offer_uuid) !== '',
          simulation_uuid: String(product?.simulation_uuid) !== '',
          simulation_option_uuid:
            String(product?.simulation_option_uuid) !== '',
          max_loan_value: Number(product?.max_loan_value) > 0,
          min_loan_value: Number(product?.min_loan_value) > 0,
          last_installment: Number(product?.last_installment) > 0,
          initial_installment: Number(product?.initial_installment) > 0,
          installments_number: Number(product?.installments_number) > 0,
          requested_loan_value: Number(product?.requested_loan_value) > 0,
          interest_rate_monthly: Number(product?.interest_rate_monthly) > 0
        },
        FGTS: {
          offer_uuid: String(product?.offer_uuid) !== '',
          simulation_uuid: String(product?.simulation_uuid) !== '',
          simulation_option_uuid:
            String(product?.simulation_option_uuid) !== '',
          interest_rate_monthly: Number(product?.interest_rate_monthly) > 0
        },
        consig: {
          offer_uuid: String(product?.offer_uuid) !== '',
          simulation_uuid: String(product?.simulation_uuid) !== '',
          simulation_option_uuid:
            String(product?.simulation_option_uuid) !== '',
          max_loan_value: Number(product?.max_loan_value) > 0,
          requested_loan_value: Number(product?.requested_loan_value) > 0,
          interest_rate_monthly: Number(product?.interest_rate_monthly) > 0,
          installments_number: Number(product?.installments_number) > 0,
          installments_value: Number(product?.installments_value) > 0
        },
        consigNcor: {
          offer_uuid: String(product?.offer_uuid) !== '',
          simulation_uuid: String(product?.simulation_uuid) !== '',
          simulation_option_uuid:
            String(product?.simulation_option_uuid) !== '',
          max_loan_value: Number(product?.max_loan_value) > 0,
          interest_rate_monthly: Number(product?.interest_rate_monthly) > 0,
          installments_number: Number(product?.installments_number) > 0
        }
      }

      const testsByProductCode = {
        recovery_debts: expectedValues.default,
        reneg_debts: expectedValues.default,
        cgi: expectedValues.cgi,
        FGTS: expectedValues.FGTS,
        'CONSIG CORR OP / Person': expectedValues.consig,
        'CONSIG CORR EP / Person': expectedValues.consig,
        'CONSIG CORR EP': expectedValues.consig,
        'CONSIG CORR OP': expectedValues.consig,
        'CONSIG CORR REFIN EP': expectedValues.consig,
        'CONSIG CORR REFIN OP': expectedValues.consig,
        'CONSIG CORR REFIN EP / Person': expectedValues.consig,
        'CONSIG CORR REFIN OP / Person': expectedValues.consig,
        consig_ncor: expectedValues.consigNcor,
        itau_account: expectedValues.default,
        azul_infinite_card: expectedValues.default,
        consig_portab_inss_ncor: expectedValues.default,
        consig_portab_op_cc: expectedValues.default,
        consig_refin_inss_ncor: expectedValues.consig,
        consig_siape: expectedValues.consigNcor,
        consig_portab_siape: expectedValues.default,
        consig_refin_siape: expectedValues.default,
        azul_platinum_card: expectedValues.default,
        pda_inter_card: expectedValues.default,
        consig_pa_ep: expectedValues.default,
        consig_pcon_op: expectedValues.default,
        consig_pcon_inss: expectedValues.default,
        consortium: expectedValues.default
      }

      if (!testsByProductCode[productCode]) {
        return
      }

      const unexpectedValues =
        Object.entries(testsByProductCode[productCode])
          .filter((item) => !item[1] ?? item)
          .reduce(
            (acc, [key]) => ({ ...acc, [key]: String(product[key]) }),
            {}
          ) ?? {}

      const hasUnexpectedValues = Object.keys(unexpectedValues).length

      const unexpectedLog = {
        product_code: productCode,
        unexpected: unexpectedValues,
        simulation_option_uuid: simulationOptionUuid
      }

      return hasUnexpectedValues ? [...acc, unexpectedLog] : acc
    }

    const unexpectedValues = data?.reduce(handleChecking, [])

    if (unexpectedValues?.length) {
      DDPageError({
        name: 'useMapperUnexpectedValues',
        payload: { errorMessage, unexpectedValues }
      })
    }
  }

  const mapSimulations = (data = []) => {
    if (!data?.length) return []

    return data?.map((simulation) => {
      let paymentOptions = []

      if (simulation?.additional_data?.installments?.length) {
        paymentOptions = simulation?.additional_data?.installments.map(
          ({ installments_value, installments_number, selected }, index) => {
            return {
              label: `${installments_number}x de ${formatters.number.toCurrency(
                installments_value
              )}`,
              value: index,
              selected: selected
            }
          }
        )
      }

      return {
        offerUuid: simulation?.offer_uuid ?? '',
        simulationOptionUuid: simulation?.simulation_option_uuid ?? '',
        simulationUuid: simulation?.simulation_uuid ?? '',
        productId: simulation?.product_id ?? '',
        productCode: simulation?.product_code ?? '',
        minLoanValue: simulation?.min_loan_value ?? 0,
        maxLoanValue: simulation?.max_loan_value ?? 0,
        requestedLoanValue:
          simulation?.requested_loan_value ?? simulation?.present_value ?? 0,
        installmentsDueDay: simulation?.installments_due_day ?? 0,
        interestRateMonthly: simulation?.interest_rate_monthly ?? 0,
        interestRateYearly: simulation?.interest_rate_yearly ?? 0,
        installmentsNumber: simulation?.installments_number ?? 0,
        maxInstallmentsNumber: simulation?.max_installments_number ?? 0,
        minInstallmentsNumber: simulation?.min_installments_number ?? 0,
        installmentsValue: simulation?.installments_value ?? 0,
        initialInstallment: simulation?.initial_installment ?? 0,
        lastInstallment: simulation?.last_installment ?? 0,
        presentValue: simulation?.present_value ?? 0,
        iofValue: simulation?.iof_value ?? 0,
        tacValue: simulation?.tac_value ?? 0,
        tacPercentageValue: simulation?.tac_percentage_value ?? 0,
        creditValue: simulation?.credit_value ?? 0,
        interestValue: simulation?.interest_value ?? 0,
        paymentValue: simulation?.payment_value ?? 0,
        cetMonthly: simulation?.cet_monthly ?? 0,
        cetYearly: simulation?.cet_yearly ?? 0,
        minDueDate: simulation?.min_due_date ?? 0,
        maxDueDate: simulation?.max_due_date ?? 0,
        lateFeePercentage: simulation?.late_fee_percentage ?? 0,
        discountPercentage: simulation?.discount_percentage ?? 0,
        interestRateLateFeeMonthly:
          simulation?.interest_rate_late_fee_monthly ?? 0,
        deliveredPercentageValue: simulation?.delivered_percentage_value ?? 0,
        feePercentageValue: simulation?.fee_percentage_value ?? 0,
        loanTotal: simulation?.loan_total ?? 0,
        loanTotalPercentageValue: simulation?.loan_total_percentage_value ?? 0,
        maxReleaseDate: formatters.date.toWords({
          date: simulation?.max_release_date,
          isShortMonth: true
        }),
        firstDueDate:
          formatters.date.toLocale(simulation?.first_due_date) ?? '',
        lastDueDate: formatters.date.toLocale(simulation?.last_due_date) ?? '',
        totalDebts: simulation?.total_debts ?? 0,
        remainingValue: simulation?.remaining_value ?? 0,
        link: simulation?.link ?? '',
        maxLoanInstallmentsValue: simulation?.max_loan_installments_value ?? 0,
        minLoanInstallmentsValue: simulation?.min_loan_installments_value ?? 0,
        additionalData: {
          creditOperationNumberOrigin:
            simulation?.additional_data?.credit_operation_number_origin ?? 0,
          customerAgreementNumber:
            simulation?.additional_data?.customer_agreement_number ?? 0,
          oldPaidInstallmentsQty:
            simulation?.additional_data?.old_paid_installments_qty ?? 0,
          oldInstallmentsValue:
            simulation?.additional_data?.old_installments_value ?? 0,
          oldInterestRateMonthly:
            simulation?.additional_data?.old_interest_rate_monthly ?? 0,
          oldInterestRateYearly:
            simulation?.additional_data?.old_interest_rate_yearly ?? 0,
          newRefinTransshipmentAmount:
            simulation?.additional_data?.new_refin_transshipment_amount ?? 0,
          newInstallmentsQty:
            simulation?.additional_data?.new_installments_qty ?? 0,
          newInstallmentsValue:
            simulation?.additional_data?.new_installments_value ?? 0,
          newInterestRateMonthly:
            simulation?.additional_data?.new_interest_rate_monthly ?? 0,
          newInterestRateYearly:
            simulation?.additional_data?.new_interest_rate_yearly ?? 0,
          newCETMonthly: simulation?.additional_data?.new_cet_monthly ?? 0,
          newCETYearly: simulation?.additional_data?.new_cet_yearly ?? 0,
          newIOFValue: simulation?.additional_data?.new_iof_value ?? 0,
          newRefinTotalAmount:
            simulation?.additional_data?.new_refin_total_amount ?? 0,
          installments: simulation?.additional_data?.installments ?? [],
          installmentsTotal:
            simulation?.additional_data?.installments_total ?? 0,
          paymentOptions: paymentOptions
        },
        ...(dictionary[simulation?.product_code] ?? dictionary['default'])
      }
    })
  }

  const mapBlocked = ({ blocked_offers = [] }) => {
    const hasBlocked = blocked_offers.length

    if (hasBlocked) {
      return mapSimulations(blocked_offers)
    } else {
      return undefined
    }
  }

  const mapOthers = ({ simulations = [] }) => {
    const otherData = simulations?.filter((item) => !item.is_hero)
    const hasOtherData = otherData?.length

    if (hasOtherData) {
      checkUnexpectedData(otherData, 'unexpected others offers values')

      const othersArray = [...mapSimulations(otherData)]

      return othersArray
    } else {
      return undefined
    }
  }

  const mapHero = ({
    simulations = [],
    offerless_channel: offerlessChannel
  }) => {
    let proposal

    const handleSimulations = (arraySimulations) => {
      let simulationHero

      const simulationHeroData = arraySimulations?.filter(
        (item) => item.is_hero
      )
      const simulationOthersOffersData = arraySimulations?.filter(
        (item) => !item.is_hero
      )

      const hasSimulationHeroData = simulationHeroData?.length
      const hasSimulationOthersOffers = simulationOthersOffersData?.length

      const isEmptyHero = !hasSimulationHeroData && !offerlessChannel
      const isEmptyOffers = !hasSimulationHeroData && !hasSimulationOthersOffers

      if (hasSimulationHeroData) {
        checkUnexpectedData(simulationHeroData, 'unexpected hero offers values')
        simulationHero = simulationHeroData
      }

      if (isEmptyHero) {
        DDPageError({
          name: 'heroEmpty'
        })
      }

      if (isEmptyOffers) {
        DDPageError({
          name: 'emptyOffers'
        })
      }

      return {
        simulationHero
      }
    }

    const { simulationHero } = handleSimulations(simulations)

    if (simulationHero) {
      proposal = mapSimulations(simulationHero)[0]
    }

    return { ...proposal, offerlessChannel }
  }

  const mapHeroFGTS = (res) => {
    let proposal

    const resAsArray = [res]

    if (resAsArray?.length) {
      proposal = mapSimulations(resAsArray)[0]
    } else {
      DDPageError({
        name: 'heroEmpty'
      })
    }

    return proposal
  }

  const mapSimulationsWhenEdit = (data) => {
    if (!data) return null

    const dataAsArray = [data]

    checkUnexpectedData(dataAsArray, 'unexpected values when edit')

    return mapSimulations(dataAsArray)[0]
  }

  return {
    checkUnexpectedData,
    mapHero,
    mapOthers,
    mapBlocked,
    mapHeroFGTS,
    mapSimulations,
    mapSimulationsWhenEdit
  }
}

export default useMapper
