import { useEffect, useState, useCallback } from 'react'

import { isClient } from 'utils'

const sizes = {
  mobilePortrait: '(max-width: 480px)',
  mobileLandscape: '(max-width: 767px)',
  tablet: '(min-width: 768px)',
  desktop: '(min-width: 1024px)',
  hd: '(min-width: 1200px)'
}

const useMediaQuery = (size = 'mobilePortrait') => {
  const query = sizes[size] ?? sizes['mobilePortrait']

  const handleHasMatch = useCallback(() => {
    if (!isClient()) return false

    return window.matchMedia(query).matches
  }, [query])

  const [hasMatches, setHasMatches] = useState(handleHasMatch)

  useEffect(() => {
    const onUpdateMedia = () => {
      const match = handleHasMatch()

      setHasMatches(match)
    }

    window.addEventListener('resize', onUpdateMedia)

    return () => {
      window.removeEventListener('resize', onUpdateMedia)
    }
  }, [handleHasMatch])

  return hasMatches
}

export default useMediaQuery
