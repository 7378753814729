const getPageAlias = ({ location }) => {
  const { pathname } = location
  const baseAlias = 'LANDING'
  const sanitizedPathname = pathname.replace(/(^\/+|\/+$)/gm, '')
  const formattedAlias = sanitizedPathname
    ? sanitizedPathname.toUpperCase()
    : baseAlias

  return formattedAlias
}

export default getPageAlias
