/* eslint-disable no-undef */
import SimpleCrypto from 'simple-crypto-js'

const instance = new SimpleCrypto(WEB_CRYPTO_ID)

const encrypt = (value) => {
  return instance.encrypt(value)
}

const decrypt = (value) => {
  return instance.decrypt(value)
}

export default {
  encrypt,
  decrypt
}
