import crypto from 'utils/crypto'

import isClient from '../envs/isClient'

const memory = {
  _storage: {},
  getItem(key) {
    return this._storage[key]
  },
  setItem(key, value) {
    this._storage[key] = value
  },
  removeItem(key) {
    this._storage = Object.keys(this._storage)
      .filter((_key) => key !== _key)
      .reduce((acc, key) => {
        return {
          ...acc,
          [key]: this._storage[key]
        }
      }, {})
  },
  clear() {
    this._storage = {}
  },
  key(index) {
    return Object.keys(this._storage)[index]
  },
  length() {
    return Object.keys(this._storage).length
  }
}

const storage = (instance) => ({
  _storage: instance,
  getItem(key) {
    const value = this._storage.getItem(key)

    if (!value) {
      return value
    }

    return crypto.decrypt(value)
  },
  setItem(key, value) {
    this._storage.setItem(key, crypto.encrypt(value))
  },
  removeItem(key) {
    this._storage.removeItem(key)
  },
  clear() {
    this._storage.clear()
  },
  key(index) {
    return this._storage.key(index)
  },
  length() {
    return this._storage.length
  }
})

export default {
  local: isClient() ? storage(window?.localStorage) : { ...memory },
  session: isClient() ? storage(window?.sessionStorage) : { ...memory }
}
