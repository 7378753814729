const trackPagename = ({ currentPage, customLayer = {} }) => {
  const data = {
    analytics: {
      event_name: 'screen_view',
      parameters: {
        custom_path: `/emprestimo/shopping/credito/${currentPage}`,
        implementation_team: 'visao-cliente:conquista-digital-de-clientes',
        ...customLayer
      }
    }
  }

  console.log(`[ GA ] - Pageview ${currentPage}`)

  return data
}

export default trackPagename
