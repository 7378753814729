import { BUILD_ENV, DATADOG } from 'gatsby-env-variables'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const ENVS = {
  development: 'dev',
  staging: 'stg',
  production: 'prod'
}

const init = () => {
  datadogRum.init({
    clientToken: DATADOG.CLIENT_TOKEN,
    applicationId: DATADOG.APP_ID,
    site: 'datadoghq.com',
    service: 'jn6_shopping_www',
    proxy: DATADOG.PROXY,
    env: ENVS[BUILD_ENV],
    sessionSampleRate: DATADOG.SESSION_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })
}

const logsInit = () => {
  datadogLogs.init({
    clientToken: DATADOG.CLIENT_TOKEN,
    proxy: DATADOG.PROXY,
    site: 'datadoghq.com',
    service: 'jn6_shopping_www',
    env: ENVS[BUILD_ENV],
    forwardConsoleLogs: ['warn', 'error']
  })
}

export default {
  init,
  logsInit
}
