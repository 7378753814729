export const getGoogleClientId = (cookie) => {
  if (!cookie) return ''
  const cookieArr = cookie.split('.')
  const cookieArrLength = cookieArr.length
  return `${cookieArr[cookieArrLength - 2]}.${cookieArr[cookieArrLength - 1]}`
  // TO DO:
  // quando voltar ao segment, matar esse util, porque:
  // cookie espera receber uma string como essa: GA1.3.311640479.1651671877
  // o make precisa receber apenas os numeros maiores dessa string
  // por isso retornamos as duas últimas posicoes do split
  // mas no segment temos zero necessidade disso
}
// TODO: PODEMOS EXPLODIR?

export const getCookie = (cookieName) => {
  const cookiesObject = document.cookie
    .split('; ')
    .reduce((initialState, param) => {
      const [name, value] = param.split('=')

      return {
        ...initialState,
        [name]: value
      }
    }, {})

  return cookiesObject[cookieName]
}
