import { isClient } from 'utils'

import storage from '../storage'

const id = 'banner-cookies'
const STYLES_CONTENT_STORAGE_KEY = 'cookie-bar-styles'

export const saveCookieBarStylesToStorage = () => {
  if (isClient()) {
    const savedStyles = storage.session.getItem(STYLES_CONTENT_STORAGE_KEY)

    if (savedStyles) return

    const $styles = document.getElementById(id)

    if (!$styles) {
      console.warn('No cookie bar styles found. Trying again.')
      return setTimeout(saveCookieBarStylesToStorage, 500)
    }

    const styles = $styles.innerText

    storage.session.setItem(STYLES_CONTENT_STORAGE_KEY, styles)
  }
}

export const getCookieBarStyles = () => {
  if (isClient()) {
    const savedStyles = storage.session.getItem(STYLES_CONTENT_STORAGE_KEY)
    return savedStyles
  }
}

export const setCookieBarStyles = (styles) => {
  if (isClient()) {
    if (document.getElementById(`${id}-internal`)) return
    if (!styles) return

    const $style = document.createElement('style')
    $style.innerHTML = styles
    document.head.appendChild($style)
  }
}
